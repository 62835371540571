export default {
  methods: {
    currency(value, currency = 'USD') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
      }).format(+value)
    }
  }
}
